<template>
<div class="alarm-rule-container">
  <div class="config-tree-area">
    <GroupTree />
  </div>
  <div class="alarm-data-area">
    <div class="alarm-data-query flex-row">
      <div class="flex-col">
        <div class="flex-row">
          灯控名称：
          <Input v-model="filter.name" type="text" clearable style="width: 200px;height:32px;margin-right: 25px;"></Input>
          通信ID：
          <Input v-model="filter.code" type="text" clearable style="width: 200px;height:32px;margin-right: 10px;"></Input>
          {{ showLang('com.state.police.type') }}：
          <Select :placeholder="showLang('save.select')" v-model="filter.type" number style="margin-right: 10px; width: 200px;">
            <Option :value="0">{{ showLang('com.state.all') }}</Option>
            <template v-for="item in types">
              <Option :value="item.code" :key="item.code">{{ item.name }}</Option>
            </template>
          </Select>
        </div>
        <div class="flex-row mt10">
          {{ showLang('com.date.start') }}：
          <DatePicker v-model="filter.start" type="datetime" format="yyyy-MM-dd HH:mm" style="margin-right: 10px; width: 200px;"></DatePicker>
          {{ showLang('com.date.end') }}：
          <DatePicker v-model="filter.end" type="datetime" format="yyyy-MM-dd HH:mm" style="margin-right: 10px; width: 200px;"></DatePicker>
          {{ showLang('com.state.police.level') }}：
          <Select :placeholder="showLang('save.select')" v-model="filter.level" number style="margin-right: 10px; width: 200px;">
            <Option :value="-1">{{ showLang('com.state.all') }}</Option>
            <template v-for="(item, idx) in levels">
              <Option :value="item.code" :key="idx">{{ item.name }}</Option>
            </template>
          </Select>
        </div>
      </div>
      <div class="flex-col">
        <div class="flex-row">
          <Button type="primary" size="default" style="margin-right: 5px" @click="getHours24">24{{showLang('hour.within') }}</Button>
          <Button type="primary" size="default" style="margin-right: 5px" @click="getHours48">24-48{{showLang('com.date.hour') }}</Button>
          <Button type="primary" size="default" style="margin-right: 5px" @click="getHoursOther">48{{showLang('hour.outside') }}</Button>
        </div>
        <div class="flex-row mt10">
          <Button v-if="funCodes(4103)" type="primary" size="default" style="margin-right: 5px" @click="getList">{{ showLang('com.op.query') }}</Button>
          <Button v-if="funCodes(4104)" type="error" @click="handleMutiDelete">{{ showLang('com.op.batch.del') }}</Button>
        </div>
      </div>
    </div>
    <div class="alarm-data-list" ref="table1">
     <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow stripe round
        keep-source ref="chkTable" @checkbox-change="selectChangeEvent" :data="list" height="auto" :row-config="{ isHover: true }"
        :checkbox-config="{ checkField: 'checked' }"  >
        <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column field="lampName" title="灯控名称" width="150" header-align="center" sortable fixed="left"></vxe-column>
        <vxe-column field="lampCode" title="通信ID" width="100" header-align="center" sortable fixed="left"></vxe-column>
        <vxe-column field="name" :title="showLang('com.state.police.type')" width="120" header-align="center" sortable></vxe-column>
        <vxe-column field="startTime" :title="showLang('com.date.occurrence')" header-align="center" sortable width="160"></vxe-column>
        <vxe-column field="doneTime" title="消除时间" header-align="center" sortable width="160"></vxe-column>
        <vxe-column field="levelName" :title="showLang('com.state.police.level')" width="100" header-align="center" sortable></vxe-column>
        <vxe-column field="extra" :title="showLang('com.state.police.info')" header-align="center" sortable width="400"></vxe-column>
        <vxe-column field="confirmName" :title="showLang('is.sure')" header-align="center" sortable width="250">
          <template #default="params">
            {{ getConfirmMessage(params) }}
          </template>
        </vxe-column>
        <vxe-column field="messageUser" :title="showLang('SMS.notification')" header-align="center" sortable width="150"></vxe-column>
        <vxe-column field="groupName" title="所在分区" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="lightGroupName" title="所在分组" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="gatewayName" title="关联网关" width="150" sortable header-align="center"></vxe-column>
        <vxe-column field="gatewayCode" title="网关ID" width="100" sortable header-align="center"></vxe-column>
        <vxe-column width="120" fixed="right" :title="showLang('com.right.nav.operate')" header-align="center">
          <template #default="{ row }">
            <Button v-if="funCodes(4103)" size="small" type="primary" style="margin-right: 5px" @click="showAlarmHisData(row)">{{ showLang('com.date.view') }}</Button>
            <Button v-if="funCodes(4104)" size="small" type="error" @click="handleDelete(row)">{{ showLang('com.op.del') }}</Button>
          </template>
        </vxe-column>
      </vxe-table>
      <ModalAlarmData v-model="showAlarmDataModal" :item="editItem" @alarmFixed="alarmFixed" />
    </div>
    <vxe-pager :layouts="pageLayouts" :page-sizes='pageSizes' :current-page.sync="filter.index" :page-size.sync="filter.size" :total="count" @page-change="handlePageChange"> </vxe-pager>
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalAlarmData from './ModalAlarmData'
import GroupTree from '../../../common/treeGroup/Index'
export default {
  name: 'AlarmLightHistory',
  components: {
    ModalAlarmData,
    GroupTree,
  },
  props: {},
  data() {
    return {
      list: [],
      tabHeight: 200,
      dataRefresh: 0,
      editItem: { isAdd: true, data: {} },
      showAlarmDataModal: false,
      filter: {
        groupId: 0,
        name: '',
        code: '',
        type: 0,
        level: -1,
        start: '2023-09-20 15:54:00',
        end: '2023-09-20 15:54:00',
        size: 20,
        index: 1,
      },
      levels: [],
      types: [],
      count: 0,
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'dealAlarmTypes', 'pageSizes', 'pageLayouts']),
    ...mapGetters('common', ['getDealAlarmMethod']),
    ...mapState('group', ['groups', 'groupTreeSelectedNode']),
  },
  watch: {
    groupTreeSelectedNode() {
      this.getList()
    }
  },
  mounted: function() {
    let now = new Date();
    // now.setDate(now.getDate() );
    this.filter.end = now.todayEnd();
    now.setDate(now.getDate() - 7);
    this.filter.start = now.todayStart();

    this.getLevels();
    this.getTypes();
  },
  destroyed: function() {},
  methods: {
    getConfirmMessage: function(params){
      if(!params.row.isConfirm)return '未确认';
      if(params.row.byUser)return `用户[${params.row.confirmName}]于[${params.row.confirmTime}]`;
      return `工单系统于[${params.row.confirmTime}]`;
    },
    getHours24: function(){
      let now = new Date();
      this.filter.end = now.todayEnd();
      now.setHours(now.getHours() - 24);
      this.filter.start = now.format('yyyy-MM-dd HH:mm:ss');
      this.getList();
    },
    getHours48: function(){
      let now = new Date();
      now.setHours(now.getHours() - 24);
      this.filter.end = now.format('yyyy-MM-dd HH:mm:ss');
      now.setHours(now.getHours() - 24);
      this.filter.start = now.format('yyyy-MM-dd HH:mm:ss');
      this.getList();
    },
    getHoursOther: function(){
      let now = new Date();
      now.setHours(now.getHours() - 48);
      this.filter.end = now.format('yyyy-MM-dd HH:mm:ss');
      now.setHours(now.getHours() - 24000);
      this.filter.start = now.format('yyyy-MM-dd HH:mm:ss');
      this.getList();
    },
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList()
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
    alarmFixed: function(){
      this.getList();
    },
    showAlarmHisData: function(item) {
      this.editItem = item
      this.showAlarmDataModal = true
    },
    tableRowClassName({ row }) {
      if (row) {
        return ''
      }
      return ''
    },
    setTableHeight: function() {
      this.tabHeight = this.$refs.table1.clientHeight - 50
    },
    handleMutiDelete: function() {
      let chks = this.$refs.chkTable.getCheckboxRecords()
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.tips.sel.del.record'))
        return
      }
      let list = chks.map(p => p.id)
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.filter.start = new Date(this.filter.start).format("yyyy-MM-dd HH:mm:ss");
          this.filter.end = new Date(this.filter.end).format("yyyy-MM-dd HH:mm:ss");
          this.$axios.post(`//${this.domains.trans}/station/alarm/DeleteLightHistory`, { list, start: this.filter.start, end: this.filter.end }).then(res => {
            if (res.code == 0) {
              this.$Message.info(this.showLang('com.tips.del.success'))
              this.getList()
            }
          })
        }
      })
    },
    handleDelete: function(row) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name', row.name),
        onOk: async () => {
          this.filter.start = new Date(this.filter.start).format("yyyy-MM-dd HH:mm:ss");
          this.filter.end = new Date(this.filter.end).format("yyyy-MM-dd HH:mm:ss");
          this.$axios.post(`//${this.domains.trans}/station/alarm/DeleteLightHistory`, { list: [row.id], start: this.filter.start, end: this.filter.end }).then(res => {
            if (res.code == 0) {
              this.$Message.info(this.showLang('com.tips.del.success'))
              this.getList()
            }
          })
        }
      })
    },
    getLevels: function(){
      this.$axios.post(`station/alarm/QueryLevels`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'levels', res.data)
        }
      })
    },
    getTypes: function(){
      this.$axios.post(`station/alarm/QueryLightTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'types', res.data)
        }
      })
    },
    getList: function() {
      this.filter.groupId = this.groupTreeSelectedNode.id;
      this.filter.start = new Date(this.filter.start).format("yyyy-MM-dd HH:mm:ss");
      this.filter.end = new Date(this.filter.end).format("yyyy-MM-dd HH:mm:ss");
      this.$axios.post(`device/light/QueryHistoryAlarm`, this.filter).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list)
          this.count = res.data.count;
        }
      })
    }
  }
}
</script>
<style scoped>
.alarm-rule-container {
  height: 100%;
  display: flex;
}
.config-tree-area{
  /* border: solid 1px #dcdee2; */
  width: 300px;
  flex: none;
}
.alarm-data-query {
  height: 80px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
}
.alarm-data-area {
  /* border: solid 1px red; */
  margin-left: 10px;
  border: solid 1px #dcdee2;
  width: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  /* border-radius: 6px; */
  display: flex;
  flex-direction: column;
  
  background: #fff;
}
.alarm-data-list{
  height: 400px;
  flex: auto;
  padding: 0 5px;
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
.alarm-data-area >>> .el-table tr.warning-row {
  background-color: #ffa8a8 !important;
}
</style>
